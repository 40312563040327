<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
// import PDFElement from "./../PDFElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);
// const filterDocs = ref("liability");
const filterProjects = ref("Liability");
const filterProjects2 = ref("Hospitality");
const filterDivision = ref("Liability");
const filterDivision2 = ref("Hospitality Liability");
const jobs = ref(clientConfig.content.jobs);
const people = ref(clientConfig.content.people);
// const pdfs = ref(clientConfig.content.docs);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="general-liability-insurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Liability</b><br />
              Insurance
            </h2>

            <img src="@/assets/img/pen/general-liability.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our experienced Liability specialists are dedicated to providing
            tailored solutions to meet your needs. With available limits of $20
            million for Primary and $30 million for Excess of Loss (XOL), along
            with optional extensions for Product Recall, Errors &amp; Omissions
            (E&amp;O), and Care, Custody, and Control (CCC), we can craft
            exclusive products designed for both established and emerging broker
            portfolios.<br /><br />We look forward to discussing how we can
            support your clients' unique requirements.
          </p>

          <h3 class="highlight">Industries & Occupations</h3>

          <section class="industry">
            <div class="industry-list row justify-content-left">
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Hospitality & Accommodation</h5>
                  <ul>
                    <li>Pubs</li>
                    <li>
                      Cafes and Restaurants (Licensed &amp; Non &amp; Licensed)
                    </li>
                    <li>Accommodation Risks</li>
                    <li>Licensed Clubs</li>
                    <li>Boarding Houses/ Hostels</li>
                    <li>Caravan Parks</li>
                    <li>Backpackers</li>
                    <li>Theatres</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Manufacturing</h5>
                  <ul>
                    <li>Food, Beverage Manufacturing</li>
                    <li>Machinery And Equipment Manufacturing</li>
                    <li>Metal Product Manufacturing</li>
                    <li>Non-Metallic Mineral Product Manufacturing</li>
                    <li>Plastics &amp; Rubber Manufacturing</li>
                  </ul>
                </div>
              </div>

              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">General Construction</h5>
                  <ul>
                    <li>Residential &amp; Non-Residential Building</li>
                    <li>Roads &amp; Bridges</li>
                    <li>Erection/ Installation Tanks &amp; Silos</li>
                    <li>Urban &amp; Suburban transport infrastructure</li>
                    <li>Renewable Energy &amp; solar</li>
                    <li>Mining &amp; extraction infrastructure</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Construction Trades</h5>
                  <ul>
                    <li>Site Preparation Services</li>
                    <li>Electrical Services</li>
                    <li>Construction Services</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Retail and Wholesale</h5>
                  <ul>
                    <li>Clothing, Furniture & Floor Covering</li>
                    <li>Garden Equipment</li>
                    <li>Meat, Seafood, Fruit and/or Vegetable</li>
                    <li>Electrical & Electronic Equipment</li>
                    <li>Machinery & Equipment</li>
                    <li>
                      Motor Vehicle New Parts Dealing & Motor Vehicle Wholesale
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Transport & Storage</h5>
                  <ul>
                    <li>Road Transport</li>
                    <li>Storage</li>
                    <li>Services to Transport</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Mining</h5>
                  <ul>
                    <li>Services to Mining</li>
                    <li>Above Ground Mining</li>
                    <li>Other Mining</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Personal & Other Services</h5>
                  <ul>
                    <li>Property Services</li>
                    <li>Business Services</li>
                    <li>Personal Services</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <!-- ======= Documents Section ======= -->
          <!-- <section id="docs" ref="docs" class="docs section-bg">
            <div class="container" data-aos="fade-up">
              <div class="section-title mt-5">
                <h2>Proposals</h2>
              </div>

              <div class="row" data-aos="fade-up" data-aos-delay="200">
                <PDFElement
                  v-for="pdf in pdfs"
                  :key="pdf"
                  :title="pdf.title"
                  :description="pdf.description"
                  :type="pdf.type"
                  :url="pdf.url"
                  :externalUrl="pdf.externalUrl"
                  v-show="pdf.type == filterDocs"
                />
              </div>
            </div>
          </section> -->
          <!-- End Docs Section -->

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="
                  member.division == filterDivision ||
                  member.division == filterDivision2
                "
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects2"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
