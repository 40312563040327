<script>
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

export default {
  components: {
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
  },
  setup() {
    const dropdown4 = ref(false);

    return {
      dropdown4,
    };
  },
};
</script>

<template>
  <MDBDropdown class="nav-item" v-model="dropdown4">
    <MDBDropdownToggle tag="a" class="nav-link" @click="dropdown4 = !dropdown4"
      ><MDBIcon />
    </MDBDropdownToggle>
    <MDBDropdownMenu>
      <MDBDropdownItem href="#/about-us">About Pen</MDBDropdownItem>
      <MDBDropdownItem>
        <!-- <MDBDropdownItem href="#/products-and-services"> -->
        <h5>Products &amp; Services</h5>
      </MDBDropdownItem>
      <div class="sub-menu">
        <MDBDropdownItem href="#/property-insurance">Property</MDBDropdownItem>
        <MDBDropdownItem href="#/general-liability">
          Liability
        </MDBDropdownItem>
        <!-- <MDBDropdownItem href="#/hospitality-liability">
          Hospitality Liability
        </MDBDropdownItem> -->
        <MDBDropdownItem href="#/care-liability">
          Care Liability
        </MDBDropdownItem>
        <MDBDropdownItem href="#/construction-insurance"
          >Construction</MDBDropdownItem
        >
        <MDBDropdownItem href="#/financial-lines">
          Financial Lines
        </MDBDropdownItem>
        <MDBDropdownItem href="#/transport-insurance"
          >Transport</MDBDropdownItem
        >
      </div>
      <MDBDropdownItem href="#/team">The Team</MDBDropdownItem>
      <MDBDropdownItem href="#/recent-wins">Recent Wins</MDBDropdownItem>
      <MDBDropdownItem href="#/contact-us">Contact Us</MDBDropdownItem>
    </MDBDropdownMenu>
  </MDBDropdown>
</template>
